@font-face {
  font-family: 'TTCommons-Bold';
  src: url('../assets/fonts/TTCommons-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'TTCommons-Medium';
  src: url('../assets/fonts/TTCommons-Medium.ttf'), local('TTCommons-Medium');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'TTCommons-Regular';
  src: url('../assets/fonts/TTCommons-Regular.ttf'), local('TTCommons-Regular');
  font-weight: 400;
  font-style: normal;
}


.View {
  .PanelHeader {
    background-color: transparent;
  }
}
